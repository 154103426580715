import React from "react";
import "./backgroundanimation.css"

export default function BackgroundAnimation () {
    return (
        <div className="animation-box">
            <div className="item"></div>
            <div className="item"></div>
            <div className="item"></div>
            <div className="item"></div>
            <div className="item"></div>
            <div className="item"></div>
            <div className="item"></div>
            <div className="item"></div>
            <div className="item"></div>
            <div className="item"></div>
        </div>
    )
}